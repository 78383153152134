import { Flex, Divider, HStack, Button, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

import { AdminList } from "~/components/AdminList";
import { ExportButton } from "~/components/ExportButton";
import { InviteButton } from "~/components/InviteButton";
import { PageHeading } from "~/components/PageHeading";
import { useDbOrgs } from "~/hooks/useDbOrgs";
import { useDbUsers } from "~/hooks/useDbUsers";
import { exportOrgAdminsToExcel } from "~/services/local-export-service";
import { cleanFilename } from "~/utils/filename-util";

const AdminListPage = () => {
  const { id } = useParams<{ id: string }>();
  const { useGetOrgById } = useDbOrgs();
  const { useGetOrgUsers } = useDbUsers();
  const navigate = useNavigate();

  const { data: org } = useGetOrgById(Number(id));
  const { data: orgUsers } = useGetOrgUsers(Number(id));

  const mergedOrg = org && orgUsers ? { ...org, admins: orgUsers.users } : null;
  const exportFileName = cleanFilename(org?.name ? `WWA_${org.name}_Admins` : "WWA_Admins");

  // Combine active users and pending invites for export
  const allAdmins = [
    ...(orgUsers?.users || []),
    ...(orgUsers?.pendingInvites || []).map(invite => ({
      ...invite,
      emailAddress: invite.email,
      orgId: Number(id),
      orgName: org?.name || ""
    }))
  ];

  return (
    <Flex direction="column" align="start">
      <PageHeading text={`Manage Admins: "${org?.name}"`} hasBackButton>
        <Stack direction={{ base: "column", md: "row" }} flexWrap={{ base: "nowrap", md: "nowrap" }}>
          <Button variant="ghost" onClick={() => navigate(`/orgs/${id}`)} size={"md"}>
            Manage Organization
          </Button>
          <HStack>
            <InviteButton orgId={Number(id)} buttonProps={{ size: "md" }} />
            <ExportButton onClick={() => exportOrgAdminsToExcel(allAdmins, cleanFilename(exportFileName))} />
          </HStack>
        </Stack>
      </PageHeading>

      <Divider my={4} />

      {mergedOrg?.admins && <AdminList admins={mergedOrg.admins} pendingInvites={orgUsers?.pendingInvites} />}
    </Flex>
  );
};

export default AdminListPage;
