import { Button, Show, HStack } from "@chakra-ui/react";
import { BsArrowDownSquareFill } from "react-icons/bs";

interface ExportButtonProps {
  onClick: () => void;
}

export const ExportButton = ({ onClick }: ExportButtonProps) => {
  return (
    <Button
      variant="outline"
      onClick={onClick}
      size={"md"}
    >
      <Show below="md">
        <BsArrowDownSquareFill />
      </Show>
      <Show above="md">
        <HStack spacing={2}>
          <BsArrowDownSquareFill />
          <span>Export</span>
        </HStack>
      </Show>
    </Button>
  );
};
