import { Table as ChakraTable, Thead, Tbody, Tr, Th, Td, Box, Text } from "@chakra-ui/react";

interface Column<T extends Record<string, unknown>> {
  header: string;
  accessor: keyof T | ((item: T) => React.ReactNode | { text: string | React.ReactNode; color?: string });
  width?: string;
  hideOnMobile?: boolean;
}

interface TableProps<T extends Record<string, unknown>> {
  columns: Column<T>[];
  data: T[];
  emptyMessage?: string;
}

export function Table<T extends Record<string, unknown>>({ columns, data, emptyMessage = "No data found." }: TableProps<T>) {
  return (
    <Box overflowX="auto" borderRadius={10} border="1px solid" borderColor="gray.200" p={4} w="100%">
      <ChakraTable>
        <Thead>
          <Tr>
            {columns.map((column, index) => (
              <Th
                key={index}
                w={column.width}
                display={{ base: column.hideOnMobile ? "none" : "table-cell", md: "table-cell" }}
                color="gray.500"
              >
                {column.header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.length > 0 ? (
            data.map((item, rowIndex) => (
              <Tr key={rowIndex}>
                {columns.map((column, colIndex) => {
                  const value = typeof column.accessor === "function"
                    ? column.accessor(item)
                    : item[column.accessor];

                  return (
                    <Td
                      key={colIndex}
                      display={{ base: column.hideOnMobile ? "none" : "table-cell", md: "table-cell" }}
                    >
                      {value && typeof value === "object" && "text" in value ? (
                        <span style={{ color: value.color }}>{value.text}</span>
                      ) : (
                        <Text as="span" noOfLines={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                          {String(value ?? '')}
                        </Text>
                      )}
                    </Td>
                  );
                })}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={columns.length}>
                <Text as="span" textAlign="center">{emptyMessage}</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </ChakraTable>
    </Box>
  );
} 