import { Box, Button, Flex, HStack, Spinner, Text } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { ExportButton } from "~/components/ExportButton";
import { OrgList } from "~/components/OrgList";
import { PageHeading } from "~/components/PageHeading";
import { UnauthorizedErrorMessage } from "~/components/UnauthorizedErrorMessage";
import { useDbOrgs } from "~/hooks/useDbOrgs";
import { useDbUsers } from "~/hooks/useDbUsers";
import { exportOrgListToExcel } from "~/services/local-export-service";

const OrgListPage = () => {
  const { useAllOrgs } = useDbOrgs();
  const { useUserInfo } = useDbUsers();
  const { data: orgs, isLoading, error } = useAllOrgs();
  const { data: userInfo } = useUserInfo();
  const navigate = useNavigate();

  const sortedOrgs = (orgs || []).sort((a, b) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const aDate = a.serviceEndDate ? new Date(a.serviceEndDate) : null;
    const bDate = b.serviceEndDate ? new Date(b.serviceEndDate) : null;

    // If either date is null, put them at the end
    if (!aDate && !bDate) return 0;
    if (!aDate) return 1;
    if (!bDate) return -1;

    // If either date is before today, put them at the end
    const aExpired = aDate < today;
    const bExpired = bDate < today;

    if (aExpired && !bExpired) return 1;
    if (!aExpired && bExpired) return -1;
    if (aExpired && bExpired) return 0;

    // Both dates are valid and not expired, sort by date
    return aDate.getTime() - bDate.getTime();
  });

  if (isLoading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner size="xl" />
        <Text ml={4}>Loading Organizations...</Text>
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex w="100%" h="100vh" justify="center" align="center">
        <UnauthorizedErrorMessage error={error.message} />
      </Flex>
    );
  }

  return (
    <Box p={[0, 4]}>
      <PageHeading text="Organizations">
        <HStack spacing={2} mt={{ base: 2, md: 0 }} flexWrap={{ base: "wrap", md: "nowrap" }}>
          <Button
            variant="outline"
            onClick={() => navigate("/orgs/new")}
            leftIcon={<FaPlus />}
            size={{ base: "sm", md: "md" }}
          >
            Add Organization
          </Button>
          <ExportButton onClick={() => exportOrgListToExcel(sortedOrgs, "WWA_Organizations.xlsx")} />
        </HStack>
      </PageHeading>
      <OrgList orgs={sortedOrgs} userIsSuperAdmin={userInfo?.isSuperAdmin} />
    </Box>
  );
};

export default OrgListPage;
