import { HStack, Icon, Text, IconButton, Button, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody, Portal, Box } from "@chakra-ui/react";
import { Organization } from "@shared/domain.models";
import { CgArrowTopRight } from "react-icons/cg";
import { FiEdit, FiCheck, FiX, FiAlertTriangle } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

import { Table } from "./Table";

interface OrgListProps {
  orgs: Organization[];
  userIsSuperAdmin?: boolean;
}

export const OrgList = ({ orgs, userIsSuperAdmin = false }: OrgListProps) => {
  const navigate = useNavigate();

  const formatDate = (date: Date | string | undefined | null) => {
    try {
      if (!date) return '';
      return new Date(date).toLocaleDateString();
    } catch (e) {
      console.error('Error formatting date:', e, date);
      return '';
    }
  };

  const getExpirationInfo = (date: Date | undefined | null) => {
    if (!date) return { isExpired: false, isExpiringSoon: false };

    const expirationDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const daysUntilExpiration = Math.ceil((expirationDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

    return {
      isExpired: expirationDate < today,
      isExpiringSoon: daysUntilExpiration > 0 && daysUntilExpiration <= 60
    };
  };

  const columns = [
    {
      header: "Organization Name",
      accessor: (org: Organization) => ({
        text: (
          <Link to={userIsSuperAdmin ? `/?orgId=${org.id}` : "/"}>
            <HStack gap={0}>
              <Text fontWeight="bold" whiteSpace="normal" overflow="hidden">
                {org.name}
              </Text>
              <Icon flexShrink={0}>
                <CgArrowTopRight size="2em" />
              </Icon>
            </HStack>
          </Link>
        )
      }),
      width: "30%"
    },
    {
      header: "Email Domains",
      accessor: (org: Organization) => ({
        text: <Text whiteSpace="normal">{org.domains.join(", ")}</Text>
      }),
      width: "25%",
      hideOnMobile: true
    },
    {
      header: "Status",
      accessor: (org: Organization) => {
        const { isExpired, isExpiringSoon } = getExpirationInfo(org.serviceEndDate);

        const mobileIcon = isExpired ? (
          <Icon as={FiX} color="red.500" boxSize="20px" />
        ) : isExpiringSoon ? (
          <Icon as={FiAlertTriangle} color="orange.500" boxSize="20px" />
        ) : (
          <Icon as={FiCheck} color="green.500" boxSize="20px" />
        );

        const desktopText = isExpired ? (
          { text: "Expired", color: "red.500" }
        ) : isExpiringSoon ? (
          { text: "Expiring Soon", color: "orange.500" }
        ) : (
          { text: "Active", color: "green.500" }
        );

        return {
          text: (
            <>
              <Box display={{ base: "block", md: "none" }}>{mobileIcon}</Box>
              <Box display={{ base: "none", md: "block" }} color={desktopText.color}>{desktopText.text}</Box>
            </>
          )
        };
      },
      width: "15%"
    },
    {
      header: "Start Date",
      accessor: (org: Organization) => ({
        text: <Text whiteSpace="nowrap">{formatDate(org.serviceStartDate)}</Text>
      }),
      width: "15%",
      hideOnMobile: true
    },
    {
      header: "Expiration Date",
      accessor: (org: Organization) => ({
        text: <Text whiteSpace="nowrap">{formatDate(org.serviceEndDate)}</Text>
      }),
      width: "15%",
      hideOnMobile: true
    },
    {
      header: "",
      accessor: (org: Organization) => ({
        text: (
          <IconButton
            aria-label="Edit"
            margin="auto"
            icon={<FiEdit />}
            variant="plain"
            onClick={() => navigate(`/orgs/${org.id}`)}
          />
        )
      }),
      width: "56px"
    }
  ];

  return (
    <Table
      columns={columns}
      data={orgs}
      emptyMessage="No organizations found."
    />
  );
}; 