import { useState, useEffect } from "react";

import { Heading, VStack, HStack, Flex, Button, Box, Text, Grid, GridItem, Image } from "@chakra-ui/react";
import { CgProfile } from "react-icons/cg";
import { GrRefresh } from "react-icons/gr";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { IoInformationCircleOutline } from "react-icons/io5";
import { MdOutlineSpeed } from "react-icons/md"      // Material Design
import { useNavigate, useSearchParams } from "react-router-dom";

import { ButtonMenu } from "~/components/ButtonMenu";
import { ExportButton } from "~/components/ExportButton";
import { PageHeading } from "~/components/PageHeading";
import { StatsBubble } from "~/components/StatsBubble";
import { TrendPannel } from "~/components/TrendPannel";
import { useDbOrgs } from "~/hooks/useDbOrgs";
import { useDbUsers } from "~/hooks/useDbUsers";
import { useAllOrgStats, type DateRangeStatsResponse } from "~/hooks/useOrgStats";
import { exportHomePageAnalytics, type HomePageAnalytics } from "~/services/local-export-service";
import { cleanFilename } from "~/utils/filename-util";

const HomePage = () => {
  const { useGetOrgById, useAllOrgs } = useDbOrgs();
  const allOrgs = useAllOrgs();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [statusFilterOptions, setStatusFilterOptions] = useState(["7 days"]);
  const [orgSelector, setOrgSelector] = useState("Change Org");
  const { useUserInfo } = useDbUsers();
  const { data: user } = useUserInfo();

  // Handle organization selection from URL or user's default org
  const urlOrgId = searchParams.get("orgId");
  const selectedOrgId = user?.isSuperAdmin && urlOrgId ? Number(urlOrgId) : user?.orgId;

  // Handle org selector change
  const handleOrgChange = (newOrgName: string) => {
    setOrgSelector(newOrgName);
    const selectedOrg = allOrgs.data?.find((org) => org.name === newOrgName);
    if (selectedOrg) {
      navigate(`/?orgId=${selectedOrg.id}`, { replace: true });
    }
  };

  // Update org selector when org data is available (only for super admins)
  useEffect(() => {
    if (user?.isSuperAdmin && selectedOrgId && allOrgs.data) {
      const selectedOrg = allOrgs.data.find((org) => org.id === selectedOrgId);
      if (selectedOrg) {
        setOrgSelector(selectedOrg.name);
      }
    }
  }, [selectedOrgId, allOrgs.data, user?.isSuperAdmin]);

  const { data: org } = useGetOrgById(selectedOrgId);

  // Get statistics data
  const { overall, last7Days, last30Days, last60Days, last90Days, isLoading: statsLoading } = useAllOrgStats(org?.id);

  const mapStatsToAnalytics = (data: DateRangeStatsResponse | undefined | null): HomePageAnalytics => {
    return {
      totalUsers: overall.data?.totalUniqueUsers ?? 0,
      totalSessionsCompleted: overall.data?.totalSessionsCompleted ?? 0,
      stressChangeOverall: overall.data?.averageStressDecrease ?? 0,
      usersLastDays: data?.activeUsers ?? 0,
      sessionsCompletedLastDays: data?.totalSessionsCompleted ?? 0,
      stressChangeLastDays: data?.averageStressDecrease ?? 0,
      startingStressLastDays: data?.averageStartingStress ?? 0,
      newUsersLastDays: data?.newUsers ?? 0,
      maxDays: overall.data?.dateRangeDays ?? 0
    };
  };

  const [statusFilter, setStatusFilter] = useState("7 days");

  // Get current stats based on filter
  const currentStats = (() => {
    switch (statusFilter) {
      case "30 days":
        return last30Days.data;
      case "60 days":
        return last60Days.data;
      case "90 days":
        return last90Days.data;
      default:
        return last7Days.data;
    }
  })();

  useEffect(() => {
    setStatusFilterOptions(
      ["7 days"]
        .concat(last30Days.data ? ["30 days"] : [])
        .concat(last60Days.data ? ["60 days"] : [])
        .concat(last90Days.data ? ["90 days"] : [])
    );
  }, [last30Days.data, last60Days.data, last90Days.data]);

  if ((!overall || statsLoading || !currentStats) && (!user?.isSuperAdmin || org)) {
    return (
      <Flex width="100%" height="100vh" justifyContent="center" alignItems="center">
        <Heading size="lg">Loading...</Heading>
      </Flex>
    );
  }

  return (
    <Flex direction="column" align="center" width="100%">
      <Box maxW="1600px" width="100%" px={{ base: 4, md: 8 }}>
        <Flex direction="column" align="center" width="100%" gap={2}>
          <PageHeading
            text={`Welcome back, ${user ? `${user.firstName || ""} ${user.lastName || ""}`.trim() : "Guest"}`}
          >
            <HStack flexWrap={{ base: "wrap", md: "nowrap" }}>
              {user?.isSuperAdmin && (
                <ButtonMenu
                  value={orgSelector}
                  setValue={handleOrgChange}
                  options={allOrgs.data?.map((org) => org.name) || []}
                />
              )}
              <Button
                variant="ghost"
                onClick={() => navigate(`/orgs/${selectedOrgId}/activity`)}
                size={{ base: "sm", md: "md" }}
              >
                View Activity
              </Button>
              {user?.orgId && (
                <Button
                  variant="outline"
                  onClick={() => navigate(`/orgs/${user?.orgId}/manage`)}
                  size={{ base: "sm", md: "md" }}
                >
                  Manage Your Organization
                </Button>
              )}
              <ExportButton
                onClick={() =>
                  exportHomePageAnalytics(
                    mapStatsToAnalytics(last7Days.data),
                    mapStatsToAnalytics(last30Days.data),
                    mapStatsToAnalytics(last60Days.data),
                    mapStatsToAnalytics(last90Days.data),
                    cleanFilename(`WWA_Analytics_${org?.name || 'All'}`)
                  )
                }
              />
            </HStack>
          </PageHeading>
          <Box>
            <Image
              src="/images/walking_outside.svg"
              width={{ base: 200, md: 350 }}
              height={{ base: 200, md: 350 }}
              alt="Person walking outside"
            />
          </Box>
          <Heading size={{ base: "md", md: "lg" }} textAlign="center">
            Your employees are taking care of their mental health.
          </Heading>
          <Heading size={{ base: "md", md: "lg" }} textAlign="center">
            Here's how you are helping.
          </Heading>
          <VStack spacing={4} width="100%" align="center">
            <HStack width="100%" mt={4}>
              <Heading size={{ base: "sm", md: "md" }} fontWeight="bold">
                Overall Statistics
              </Heading>
            </HStack>
            <Grid
              width="100%"
              paddingX={{ base: 0, md: 4 }}
              templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
              templateRows={{ base: "repeat(2, auto)", md: "1fr" }}
              gap={4}
              alignItems="stretch"
            >
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <StatsBubble
                  header="Users"
                  body={
                    <Box display="flex" alignItems="flex-end">
                      <Heading size="lg" color="black">
                        {overall.data?.totalUniqueUsers ?? 0}
                      </Heading>
                      <Heading size="md" color="black">
                        {overall.data?.maxUsers ? `/${overall.data.maxUsers}` : ""}
                      </Heading>
                    </Box>
                  }
                />
              </GridItem>
              <GridItem colSpan={{ base: 1, md: 1 }}>
                <StatsBubble
                  header="Sessions Completed"
                  body={
                    <Heading size="lg" color="black">
                      {overall.data?.totalSessionsCompleted ?? 0}
                    </Heading>
                  }
                />
              </GridItem>
              <GridItem colSpan={{ base: 1, md: 1 }}>
                <StatsBubble
                  header="Average Stress Decrease"
                  body={
                    <Heading size="lg" color={(overall.data?.averageStressDecrease ?? 0) > 0 ? "red.300" : "black"}>
                      {(-1 * (overall.data?.averageStressDecrease ?? 0)).toFixed(0)}
                    </Heading>
                  }
                >
                  <Text fontSize="sm" color="gray.500" display="flex" alignItems="center">
                    <Box as="span" display="inline-block" mr="1">
                      <IoInformationCircleOutline />
                    </Box>
                    Delta of stress score before and after session
                  </Text>
                </StatsBubble>
              </GridItem>
            </Grid>
          </VStack>
          <VStack spacing={4} width="100%">
            <HStack width="100%">
              <Heading size={{ base: "sm", md: "md" }}>Trending in the last</Heading>
              <ButtonMenu value={statusFilter} setValue={setStatusFilter} options={statusFilterOptions} />
            </HStack>
            {overall.data?.dateRangeDays === 0 ? (
              <Box p={8} textAlign="center" width="100%" borderRadius="10" border="1px solid LightGray">
                <Text color="gray.500">Not enough usage data to display trends yet</Text>
              </Box>
            ) : (
              <>
                <Grid
                  width="100%"
                  paddingX={{ base: 0, md: 4 }}
                  templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
                  gap={4}
                  alignItems="stretch"
                >
                  <TrendPannel
                    icon={CgProfile}
                    header="New Users"
                    body={currentStats?.newUsers.toString() ?? "0"}
                    percentChange={currentStats?.percentChanges.newUsers ?? 0}
                  />
                  <TrendPannel
                    icon={HiOutlineUserGroup}
                    header="Active Users"
                    body={currentStats?.activeUsers.toString() ?? "0"}
                    percentChange={currentStats?.percentChanges.activeUsers ?? 0}
                  />
                  <TrendPannel
                    icon={GrRefresh}
                    header="Sessions Completed"
                    body={currentStats?.totalSessionsCompleted.toString() ?? "0"}
                    percentChange={currentStats?.percentChanges.totalSessionsCompleted ?? 0}
                  />
                  <TrendPannel
                    icon={MdOutlineSpeed}
                    header="Avg. Starting Stress"
                    body={(currentStats?.averageStartingStress ?? 0).toFixed(0)}
                    percentChange={currentStats?.percentChanges.averageStartingStress ? Number(currentStats.percentChanges.averageStartingStress.toFixed(0)) : 0}
                  />
                </Grid>
                <Text fontSize="sm" color="gray.500" textAlign="center">
                  Total history: {overall.data?.dateRangeDays ?? 0} days
                </Text>
              </>
            )}
          </VStack>
        </Flex>
      </Box>
    </Flex>
  );
};

export default HomePage;
