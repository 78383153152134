import { Box, Icon, Text, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody, Portal } from "@chakra-ui/react";
import { UserInfo } from "@shared/domain.models";
import { FiMoreVertical } from "react-icons/fi";

import { Table } from "./Table";

interface PendingInvite {
  email: string;
  createdAt: Date | string;
  expiresAt: Date | string;
}

interface AdminListProps {
  admins: UserInfo[];
  pendingInvites?: PendingInvite[];
}

export const AdminList = ({ admins, pendingInvites = [] }: AdminListProps) => {
  const formatDate = (date: Date | string) => {
    try {
      if (!date) return '';

      // If it's already a Date object
      if (date instanceof Date) {
        return date.toLocaleDateString();
      }

      // If it's a string, create a new Date object
      // This will handle both ISO format and YYYY-MM-DD format
      return new Date(date).toLocaleDateString();
    } catch (e) {
      console.error('Error formatting date:', e, date);
      return '';
    }
  };

  const columns = [
    {
      header: "Admin Name",
      accessor: (item: UserInfo | PendingInvite) =>
        'email' in item && !('id' in item) ? "Pending" : `${(item as UserInfo).firstName} ${(item as UserInfo).lastName}`,
      width: "30%"
    },
    {
      header: "Admin Email",
      accessor: (item: UserInfo | PendingInvite) =>
        'email' in item ? item.email : (item as UserInfo).emailAddress,
      width: "35%",
      hideOnMobile: true
    },
    {
      header: "Status",
      accessor: (item: UserInfo | PendingInvite) => ({
        text: 'email' in item && !('id' in item) ? "Invited" : ((item as UserInfo).isActive ? "Active" : "Inactive"),
        color: 'email' in item && !('id' in item) ? "orange.500" : ((item as UserInfo).isActive ? "green.500" : "red.500")
      }),
      width: "20%"
    },
    {
      header: "Date Added",
      accessor: (item: UserInfo | PendingInvite) => formatDate('createdAt' in item ? item.createdAt : (item as UserInfo).createdAt),
      width: "15%",
      hideOnMobile: true
    },
    {
      header: "",
      accessor: (item: UserInfo | PendingInvite) => ({
        text: (
          <span style={{ display: 'inline-block' }}>
            <Box display={{ base: "inline-block", md: "none" }}>
              <Popover>
                <PopoverTrigger>
                  <IconButton
                    aria-label="Show more"
                    icon={<Icon as={FiMoreVertical} />}
                    variant="plain"
                    size="sm"
                  />
                </PopoverTrigger>
                <Portal>
                  <PopoverContent maxWidth="300px">
                    <PopoverArrow />
                    <PopoverBody>
                      <Box>
                        <Text as="span" fontWeight="bold" display="block" mb={2}>Email:</Text>
                        <Text as="span" display="block" mb={4}>{('email' in item ? item.email : (item as UserInfo).emailAddress)}</Text>
                        <Text as="span" fontWeight="bold" display="block" mb={2}>Date Added:</Text>
                        <Text as="span" display="block">{formatDate('createdAt' in item ? item.createdAt : (item as UserInfo).createdAt)}</Text>
                      </Box>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </Box>
          </span>
        )
      }),
      width: "56px"
    }
  ];

  const allItems = [...admins, ...pendingInvites];

  return (
    <Table
      columns={columns}
      data={allItems}
      emptyMessage="No admins found."
    />
  );
};
